
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Model} from 'vue-property-decorator';
    import {DateTime} from 'luxon';

    @Component<BirthDateInput>({})
    export default class BirthDateInput extends Vue {
        @Model('input', {type: String}) public readonly value!: string|null;

        private day: string = '01';
        private month: string = '01';
        private year: string|null = null;
        private birthDate: string|null = null;

        private birthDateRules = [
            (value: string) => !!value || 'La date de naissance est requise',
            (value: string) => (!!value && DateTime.fromISO(value).isValid) || 'La date de naissance est invalide',
        ];

        private onInputChange() {
            this.birthDate = `${this.format4digits(this.year)}-${this.format2digits(this.month)}-${this.format2digits(this.day)}`;
            this.$emit('input', this.birthDate);
        }

        private onBlurYear() {
            this.year = this.format4digits(this.year) ?? this.year;
        }

        private onBlurMonth() {
            this.month = this.format2digits(this.month) ?? this.month;
        }

        private onBlurDay() {
            this.day = this.format2digits(this.day) ?? this.day;
        }

        private format4digits(value: string|null) {
            if (!value || isNaN(parseInt(value, 10))) {
                return null;
            }

            if (value && value.length === 2) {
                const year = new Date().getFullYear().toString().slice(-2);

                if (value > year) {
                    return `19${value}`;
                }

                return `20${value}`;
            }

            return value;
        }

        private format2digits(value: string|null) {
            if (!value) {
                return '01';
            }

            if (isNaN(parseInt(value, 10))) {
                return '01';
            }

            if (value.length === 1) {
                return `0${value}`;
            }

            return value;
        }

        private updateFields() {
            if (!this.value) {
                this.day = '01';
                this.month = '01';
                this.year = null;

                return;
            }

            let year; let month; let day;

            [year, month, day] = this.value.split('-');

            this.year = year;
            this.month = month;
            this.day = day;
            this.birthDate = this.value;
        }

        private mounted() {
            this.updateFields();
        }
    }
